import { Button, Row, Tag } from 'antd';

import { t } from '@gowgates/utils';
import { User } from '@gowgates/core';

type UserDetailsProps = {
  user: User;
  onEditClick: () => void;
};

const UserDetails = ({ user, onEditClick }: UserDetailsProps) => (
  <dl className="cg-descriptions">
    <dt>{t('activerecord.attributes.user.firstName')}</dt>
    <dd>{user.firstName}</dd>

    <dt>{t('activerecord.attributes.user.lastName')}</dt>
    <dd>{user.lastName}</dd>

    <dt>{t('activerecord.attributes.user.email')}</dt>
    <dd>{user.email}</dd>

    <dt>{t('activerecord.attributes.user.roleId')}</dt>
    <dd>{user.role}</dd>

    {user.permissions.showPermissions && (
      <>
        <dt>{t('activerecord.attributes.user.permissionIds')}</dt>
        <dd>{user.permissionsNames?.map((permission) => <Tag>{permission}</Tag>)}</dd>
      </>
    )}

    {user.permissions.update && (
      <Row className="d-flex justify-content-end">
        <Button onClick={onEditClick}>{t('globals.edit')}</Button>
      </Row>
    )}
  </dl>
);

export default UserDetails;
