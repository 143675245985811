import PropTypes from 'prop-types';
import { DynamicDescriptions } from '@gowgates/dynamic-fields';

const SectionDescription = ({ sectionStructure, section }) => (
  <DynamicDescriptions model={section} fields={sectionStructure.fields} />
);

SectionDescription.propTypes = {
  sectionStructure: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired
};

export default SectionDescription;
