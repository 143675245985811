import { extractData, extractDataAndPagination, apiConfigs } from '@gowgates/api-client';
import type { Field, ListItem } from '@gowgates/dynamic-fields';
import { User, Role, Permission } from '@gowgates/core';
import { AnyObject, UrlId } from '@gowgates/utils';
import { Tenant } from '@gowgates/claims-gateway-shared';

import {
  type Claim,
  type Section,
  type Note,
  type Document,
  type Task,
  type Account,
  type ClaimStructure,
  type SectionStructure,
  type DocumentStructure,
  type ReportStructure,
  type ReportField,
  type Journey,
  type TaskStructure,
  type TaskTrigger,
  type Integration,
  type EmailTemplate,
  type TaskStructureEntity,
  type ButtonAction,
  type TaskHistory,
  type TaskInTable,
  type CreateClaimParams,
  type ClaimInTable,
  type Structure,
  HomeStatistics,
  UserStatistics,
  Payment
} from '../types';
import { Cover } from '../types/cover';

export type ID = number | string;
const client = () => apiConfigs().clientInstance;

// Endpoints

// Statistics
export const getStatistics = () =>
  client()
    .get('/statistics')
    .then(extractData<HomeStatistics>);
export const getUsersStatistics = () =>
  client()
    .get('/statistics/users')
    .then(extractData<UserStatistics[]>);

// Claims
export const getClaims = (params = {}) =>
  client()
    .get('claims', { params })
    .then(extractDataAndPagination<ClaimInTable>);
export const getClaim = (id: UrlId) => client().get(`claims/${id}`).then(extractData);
export const createClaim = (values: CreateClaimParams) =>
  client()
    .post('claims', { params: values.params, data: { claim: values.claim } })
    .then(extractData);
export const deleteClaim = (id: UrlId) => client().delete(`/claims/${id}`).then(extractData);

export const updateClaimClaimant = (id: UrlId, values: Claim) =>
  client()
    .put(`/claims/${id}/claimant`, { data: { claimant: values } })
    .then(extractData);
export const updateClaimClient = (id: UrlId, values: Claim) =>
  client()
    .put(`/claims/${id}/client`, { data: { client: values } })
    .then(extractData);
export const updateClaimCover = (id: UrlId, values: Cover) =>
  client()
    .put(`/claims/${id}/cover`, { data: { cover: values } })
    .then(extractData);

export const assignClaimsToMe = (claimId: React.Key[]) =>
  client().patch('claims/assign', { data: { claimId } }).then(extractData);
export const assignClaims = (claimId: React.Key[], userId: UrlId) =>
  client().patch(`claims/assign/${userId}`, { data: { claimId } }).then(extractData);

export const assignClaimToMe = (id: UrlId) =>
  client().patch(`/claims/${id}/assign`).then(extractData);
export const assignClaim = (id: UrlId, userId: UrlId) =>
  client().patch(`/claims/${id}/assign/${userId}`).then(extractData);
export const sendClaimAction = (id: UrlId, action: string, claim: Partial<Claim> = {}) =>
  client().patch(`/claims/${id}/action/${action}`, { data: { claim } }).then(extractData);
// TODO: Not the most correct name. should be sth like completeTaskTrigger or fireTaskTrigger
export const performButtonAction = ({
  recordId,
  entity,
  taskTriggerId,
  params,
  commentParams
}: {
  recordId?: UrlId;
  entity: TaskStructureEntity;
  taskTriggerId: ID;
  params?: AnyObject;
  commentParams?: AnyObject;
}) => {
  const data: {
    recordId?: ID;
    recordType: TaskStructureEntity;
    comment?: AnyObject;
  } & { [key in TaskStructureEntity]?: AnyObject } = {
    recordId,
    recordType: entity
  };

  if (params) {
    data[entity] = params;
  }

  if (commentParams) {
    data.comment = commentParams;
  }

  return client().put(`button_actions/${taskTriggerId}/complete`, { data }).then(extractData);
};

// Bulk actions for payments
export const performButtonActionInBulk = ({
  taskTriggerId,
  records,
  entity,
  params = {},
  commentParams = {}
}: {
  taskTriggerId: ID;
  records: any[];
  entity: TaskStructureEntity;
  params: any;
  commentParams: any;
}) =>
  client()
    .put(`/button_actions/${taskTriggerId}/complete_bulk`, {
      data: {
        recordIds: records.map(({ id }) => id),
        recordType: entity,
        [entity]: params,
        comment: commentParams
      }
    })
    .then(extractData);

// Items
export const getItem = (claimId: UrlId, id: UrlId) =>
  client().get(`/claims/${claimId}/items/${id}`).then(extractData);
export const createItem = (claimId: UrlId, sectionId: UrlId, item: any) =>
  client()
    .post(`/claims/${claimId}/sections/${sectionId}/items`, { data: { item } })
    .then(extractData);
export const updateItem = (claimId: UrlId, sectionId: UrlId, id: UrlId, item: any) =>
  client()
    .put(`/claims/${claimId}/sections/${sectionId}/items/${id}`, { data: { item } })
    .then(extractData);
export const deleteItem = (claimId: UrlId, sectionId: UrlId, id: UrlId) =>
  client().delete(`/claims/${claimId}/sections/${sectionId}/items/${id}`).then(extractData);
export const sendItemAction = (
  claimId: UrlId,
  sectionId: UrlId,
  id: UrlId,
  action: string,
  item: any
) =>
  client()
    .patch(`/claims/${claimId}/sections/${sectionId}/items/${id}/action/${action}`, {
      data: { item }
    })
    .then(extractData);

// Sections
export const updateSection = (claimId: UrlId, id: UrlId, section: Section) =>
  client().put(`/claims/${claimId}/sections/${id}`, { data: { section } }).then(extractData);
export const createSection = (claimId: UrlId, section: Section) =>
  client().post(`/claims/${claimId}/sections`, { data: { section } }).then(extractData);
export const deleteSection = (claimId: UrlId, id: UrlId) =>
  client().delete(`/claims/${claimId}/sections/${id}`).then(extractData);

// Claim notes
export const getClaimNotes = (id: UrlId, params = {}) =>
  client().get(`/claims/${id}/notes`, { params }).then(extractData);

// Claim tasks
export const getClaimTasks = (id: UrlId) => client().get(`/claims/${id}/tasks`).then(extractData);

// Claim documents
export const getClaimDocuments: (id: UrlId) => Promise<Document[]> = (id) =>
  client().get(`/claims/${id}/documents`).then(extractData);

// Notes
export const createNote = (note: Note) =>
  client()
    .post('/notes', { data: { note } })
    .then(extractData<Note>);
export const deleteNote = (id: UrlId) => client().delete(`/notes/${id}`).then(extractData);

// Documents
export const createDocument = (document: Document) =>
  client().post('documents', { data: { document } }).then(extractData);
export const updateDocument = (id: UrlId, document: Document) =>
  client().patch(`documents/${id}`, { data: { document } }).then(extractData);
export const deleteDocument = (id: UrlId) => client().delete(`documents/${id}`).then(extractData);
export const getDocumentActions: (
  claimId: UrlId,
  id: UrlId
) => Promise<{ actions: ButtonAction[] }> = (claimId: UrlId, id: UrlId) =>
  client().get(`/claims/${claimId}/documents/${id}/actions`).then(extractData);

// Insight
export const getPolicyTransaction = (id: UrlId, integrationId: UrlId, claimId: UrlId) =>
  client()
    .get(`/insight/policy_transactions/${id}`, {
      params: { integration_id: integrationId, claim_id: claimId }
    })
    .then(extractData);

// Tasks
export const getTasks = (params = {}) =>
  client()
    .get('tasks', { params })
    .then(extractDataAndPagination<TaskInTable>);
export const createTask = (claimId: UrlId, task: Task) =>
  client().post(`/claims/${claimId}/tasks`, { data: { task } }).then(extractData);
export const updateTask = (id: UrlId, task: Task) =>
  client().patch(`/tasks/${id}`, { data: task }).then(extractData);
export const resendCompleteClaimantTaskEmail = (id: UrlId) =>
  client().post(`/tasks/${id}/resend_complete_claimant_task_email`, {}).then(extractData);
export const assignTasksToMe = (taskId: React.Key[]) =>
  client().patch('tasks/assign', { data: { taskId } }).then(extractData);
export const assignTasks = (taskId: React.Key[], userId: UrlId) =>
  client().patch(`tasks/assign/${userId}`, { data: { taskId } }).then(extractData);
export const completeTask = (id: UrlId) =>
  client().patch(`/tasks/${id}/complete`).then(extractData);
export const cancelTask = (id: UrlId) => client().patch(`/tasks/${id}/cancel`).then(extractData);
export const getTaskHistory: (id: UrlId) => Promise<TaskHistory[]> = (id) =>
  client().get(`/tasks/${id}/history`).then(extractData);

// Users
export const getUsers = (params = {}) =>
  client()
    .get('users', { params })
    .then(extractDataAndPagination<User>);
export const getUser = (id: UrlId) =>
  client()
    .get(`users/${id}`)
    .then(extractData<User>);
export const newUser = () =>
  client()
    .get('/users/new')
    .then(extractData<User>);
export const createUser = (user: Partial<User>) =>
  client()
    .post('/users', { data: { user } })
    .then(extractData<User>);
export const updateUser = (id: UrlId, user: Partial<User>) =>
  client()
    .put(`users/${id}`, { data: { user } })
    .then(extractData<User>);
export const confirmUser = (id: UrlId) =>
  client()
    .patch(`users/${id}/confirm`)
    .then(extractData<User>);
export const unlockUser = (id: UrlId) => client().patch(`users/${id}/unlock`).then(extractData);
export const resetUserPassword = (id: UrlId) =>
  client().patch(`users/${id}/reset_password`).then(extractData);
export const blockUser = (id: UrlId) =>
  client()
    .patch(`users/${id}/block`)
    .then(extractData<User>);
export const unblockUser = (id: UrlId) =>
  client()
    .patch(`users/${id}/unblock`)
    .then(extractData<User>);
export const disableOtp = (id: UrlId) =>
  client().patch(`users/${id}/disable_otp`).then(extractData);
export const sendConfirmationToUser = (id: UrlId) =>
  client()
    .patch(`users/${id}/send_confirmation_instructions`)
    .then(extractData<User>);
export const sendUnlockToUser = (id: UrlId) =>
  client().patch(`users/${id}/send_unlock_instructions`).then(extractData);

// Accounts
export const getAccounts = () => client().get('/accounts').then(extractData);
export const getAccount = (id: UrlId) => client().get(`/accounts/${id}`).then(extractData);
export const updateAccount = (id: UrlId, account: Account) =>
  client().patch(`/accounts/${id}`, { data: { account } }).then(extractData);

// Structures
export const getStructures = () =>
  client()
    .get('/structures')
    .then(extractData<Structure[]>);
export const createStructure = (claimStructureId: UrlId) =>
  client()
    .post('/structures', { data: { structure: { claimStructureId } } })
    .then(extractData<Structure>);
export const updateStructure = (claimStructureId: UrlId) =>
  client()
    .patch('/structures', { data: { structure: { claimStructureId } } })
    .then(extractData<Structure>);

// Claim structures
export const getClaimStructures = () => client().get('claim_structures').then(extractData);
export const getClaimStructure = (id: UrlId) =>
  client().get(`claim_structures/${id}`).then(extractData);
export const createClaimStructure = (claimStructure: ClaimStructure) =>
  client().post('/claim_structures', { data: { claimStructure } }).then(extractData);
export const updateClaimStructure = (id: UrlId, claimStructure: ClaimStructure) =>
  client().put(`claim_structures/${id}`, { data: { claimStructure } }).then(extractData);

// Section structures
export const getSectionStructures = (claimStructureId: UrlId) =>
  client().get(`/claim_structures/${claimStructureId}/section_structures`).then(extractData);
export const getSectionStructure = (claimStructureId: UrlId, id: UrlId) =>
  client().get(`/claim_structures/${claimStructureId}/section_structures/${id}`).then(extractData);
export const createSectionStructure = (
  claimStructureId: UrlId,
  sectionStructure: SectionStructure
) =>
  client()
    .post(`/claim_structures/${claimStructureId}/section_structures`, {
      data: { sectionStructure }
    })
    .then(extractData);
export const updateSectionStructure = (
  claimStructureId: UrlId,
  id: UrlId,
  sectionStructure: SectionStructure
) =>
  client()
    .put(`/claim_structures/${claimStructureId}/section_structures/${id}`, {
      data: { sectionStructure }
    })
    .then(extractData);

// Document structures
export const getDocumentStructures = (claimStructureId: UrlId) =>
  client().get(`/claim_structures/${claimStructureId}/document_structures`).then(extractData);
export const getDocumentStructure = (claimStructureId: UrlId, id: UrlId) =>
  client().get(`/claim_structures/${claimStructureId}/document_structures/${id}`).then(extractData);
export const createDocumentStructure = (
  claimStructureId: UrlId,
  documentStructure: DocumentStructure
) =>
  client()
    .post(`/claim_structures/${claimStructureId}/document_structures`, {
      data: { documentStructure }
    })
    .then(extractData);
export const updateDocumentStructure = (
  claimStructureId: UrlId,
  id: UrlId,
  documentStructure: DocumentStructure
) =>
  client()
    .put(`/claim_structures/${claimStructureId}/document_structures/${id}`, {
      data: { documentStructure }
    })
    .then(extractData);
export const deleteDocumentStructure = (claimStructureId: UrlId, id: UrlId) =>
  client()
    .delete(`/claim_structures/${claimStructureId}/document_structures/${id}`)
    .then(extractData);

// Report structures
export const getReportStructures = (claimStructureId: UrlId) =>
  client().get(`/claim_structures/${claimStructureId}/report_structures`).then(extractData);
export const getReportStructure = (claimStructureId: UrlId, id: UrlId) =>
  client().get(`/claim_structures/${claimStructureId}/report_structures/${id}`).then(extractData);
export const createReportStructure = (claimStructureId: UrlId, reportStructure: ReportStructure) =>
  client()
    .post(`/claim_structures/${claimStructureId}/report_structures`, { data: { reportStructure } })
    .then(extractData);
export const updateReportStructure = (
  claimStructureId: UrlId,
  id: UrlId,
  reportStructure: ReportStructure
) =>
  client()
    .put(`/claim_structures/${claimStructureId}/report_structures/${id}`, {
      data: { reportStructure }
    })
    .then(extractData);
export const deleteReportStructure = (claimStructureId: UrlId, id: UrlId) =>
  client()
    .delete(`/claim_structures/${claimStructureId}/report_structures/${id}`)
    .then(extractData);

// Report structure fields
export const getReportFields = (claimStructureId: UrlId, reportStructureId: UrlId) =>
  client()
    .get(`/claim_structures/${claimStructureId}/report_structures/${reportStructureId}/fields`)
    .then(extractData<ReportField[]>);
export const updateReportField = (
  claimStructureId: UrlId,
  reportStructureId: UrlId,
  id: UrlId,
  reportField: ReportField
) =>
  client()
    .put(
      `/claim_structures/${claimStructureId}/report_structures/${reportStructureId}/fields/${id}`,
      { data: { reportField } }
    )
    .then(extractData);
export const deleteReportField = (claimStructureId: UrlId, reportStructureId: UrlId, id: UrlId) =>
  client()
    .delete(
      `/claim_structures/${claimStructureId}/report_structures/${reportStructureId}/fields/${id}`
    )
    .then(extractData);

// Fields
export const getFields = (url: string) => client().get(url).then(extractData);
export const createField = (url: string, field = {}) =>
  client().post(`${url}`, { data: { field } }).then(extractData);
export const deleteField = (id: UrlId) =>
  client().delete(`/dynamic_data/fields/${id}`).then(extractData);
export const updateField = (id: UrlId, field: Field) =>
  client().put(`/dynamic_data/fields/${id}`, { data: { field } }).then(extractData);

// Journeys
export const getJourneys = (params: AnyObject = {}) =>
  client()
    .get('journeys', { params })
    .then(extractData<Journey[]>);
export const getJourney = (id: UrlId) =>
  client()
    .get(`journeys/${id}`)
    .then(extractData<Journey>);
export const createJourney = (journey: Journey) =>
  client()
    .post('/journeys', { data: { journey } })
    .then(extractData<Journey>);
export const updateJourney = (id: UrlId, journey: Journey) =>
  client()
    .put(`journeys/${id}`, { data: { journey } })
    .then(extractData<Journey>);

// Task structures
export const getTaskStructures = (journeyId: UrlId, params = {}) =>
  client()
    .get(`/journeys/${journeyId}/task_structures`, { params })
    .then(extractData<TaskStructure[]>);
export const getTaskStructure = (journeyId: UrlId, id: UrlId) =>
  client().get(`/journeys/${journeyId}/task_structures/${id}`).then(extractData);
export const createTaskStructure = (journeyId: UrlId, taskStructure: TaskStructure) =>
  client()
    .post(`/journeys/${journeyId}/task_structures`, { data: { taskStructure } })
    .then(extractData);
export const deleteTaskStructure = (journeyId: UrlId, id: UrlId) =>
  client().delete(`/journeys/${journeyId}/task_structures/${id}`).then(extractData);
export const updateTaskStructure = (journeyId: UrlId, id: UrlId, taskStructure: TaskStructure) =>
  client()
    .put(`/journeys/${journeyId}/task_structures/${id}`, { data: { taskStructure } })
    .then(extractData);

// Task triggers
export const createTaskTrigger = (journeyId: UrlId, id: UrlId, taskTrigger: TaskTrigger) =>
  client()
    .post(`/journeys/${journeyId}/task_structures/${id}/task_triggers`, { data: { taskTrigger } })
    .then(extractData);
export const updateTaskTrigger = (
  journeyId: UrlId,
  taskStructureId: UrlId,
  id: UrlId,
  taskTrigger: TaskTrigger
) =>
  client()
    .put(`/journeys/${journeyId}/task_structures/${taskStructureId}/task_triggers/${id}`, {
      data: { taskTrigger }
    })
    .then(extractData);
export const deleteTaskTrigger = (journeyId: UrlId, taskStructureId: UrlId, id: UrlId) =>
  client()
    .delete(`/journeys/${journeyId}/task_structures/${taskStructureId}/task_triggers/${id}`)
    .then(extractData);

// Devise
export const getMe = () =>
  client()
    .get('/users/me')
    .then(extractData<User>);
export const getOtpQrCode = () => client().get('profile/otps/qr_code').then(extractData);
export const createOtp = (otp: any) =>
  client().post('profile/otps', { data: { otp } }).then(extractData);
export const updatePassword = (user: any) =>
  client().patch('/profile/password', { data: { user } }).then(extractData);

// Integrations
export const getIntegrations = (params = {}) =>
  client()
    .get('integrations', { params })
    .then(extractData<Integration[]>);
export const getIntegration = (id: UrlId) =>
  client()
    .get(`integrations/${id}`)
    .then(extractData<Integration>);
export const createIntegration = (integration: Integration) =>
  client()
    .post('/integrations', { data: { integration } })
    .then(extractData<Integration>);
export const updateIntegration = (id: UrlId, integration: Integration) =>
  client()
    .put(`integrations/${id}`, { data: { integration } })
    .then(extractData<Integration>);
export const deleteIntegration = (id: UrlId) =>
  client()
    .delete(`integrations/${id}`)
    .then(extractData<void>);

// Lists
export const getListItems = (params = {}) =>
  client()
    .get('dynamic_data/list_items', { params })
    .then(extractData<ListItem[]>);
export const getListItem = (id: UrlId) =>
  client()
    .get(`dynamic_data/list_items/${id}`)
    .then(extractData<ListItem>);
export const createListItem = (listItem: ListItem) =>
  client()
    .post('/dynamic_data/list_items', { data: { listItem } })
    .then(extractData<ListItem>);
export const updateListItem = (id: UrlId, listItem: ListItem) =>
  client().put(`dynamic_data/list_items/${id}`, { data: { listItem } }).then(extractData);
export const deleteListItem = (id: UrlId) =>
  client().delete(`dynamic_data/list_items/${id}`).then(extractData);

// Roles
export const getRoles = () => client().get('roles').then(extractData);
export const getRole = (id: UrlId) =>
  client()
    .get(`roles/${id}`)
    .then(extractData<Role>);
export const createRole = (role: Role) =>
  client().post('/roles', { data: { role } }).then(extractData);
export const updateRole = (id: UrlId, role: Role) =>
  client().put(`roles/${id}`, { data: { role } }).then(extractData);
export const deleteRole = (id: UrlId) => client().delete(`roles/${id}`).then(extractData);

// Permissions
export const getPermissions = () =>
  client()
    .get('permissions')
    .then(extractData<Permission[]>);
export const getPermission = (id: UrlId) =>
  client()
    .get(`permissions/${id}`)
    .then(extractData<Permission>);
export const createPermission = (permission: Partial<Permission>) =>
  client()
    .post('/permissions', { data: { permission } })
    .then(extractData<Permission>);
export const updatePermission = (id: UrlId, permission: Permission) =>
  client()
    .put(`permissions/${id}`, { data: { permission } })
    .then(extractData<Permission>);
export const deletePermission = (id: UrlId) =>
  client()
    .delete(`permissions/${id}`)
    .then(extractData<void>);

// App configs
export const getFieldTemplates = () => client().get('/field_templates.json').then(extractData);
export const updateFileConfig = (data: any) =>
  client().put('/tenant/file_config', { data }).then(extractData);
export const updateTenant = (values: Partial<Tenant>) =>
  client()
    .put('/tenant', { data: { tenant: values } })
    .then(extractData);

// Resources
export const getResourceUsers = (params = {}) =>
  client()
    .get('resources/users', { params })
    .then(extractData<User[]>);
export const getResourceIntegrations = () =>
  client()
    .get('resources/integrations')
    .then(extractData<Integration[]>);
export const getMyPermissions = () => client().get('users/permissions').then(extractData);
export const getResourceRoles = () =>
  client()
    .get('resources/roles')
    .then(extractData<Role[]>);
export const getResourcePermissions = () => client().get('resources/permissions').then(extractData);
export const getResourceTaskStructures = (journeyId: UrlId) =>
  client()
    .get('resources/task_structures', { params: { journey_id: journeyId } })
    .then(extractData<TaskStructure[]>);
export const getAppConfigs = () => client().get('resources/app_configs').then(extractData);
export const getFileConfig = () =>
  client()
    .get('resources/file_config')
    .then(
      extractData<{
        claimant: Field[];
        client: Field[];
        cover: Field[];
      }>
    );

// Payments
export const getPayments = (params = {}) =>
  client()
    .get('payments', { params })
    .then(extractDataAndPagination<Payment>);

// Reports
export const getAllClaims = (params = {}) =>
  client().get('claims/all', { params }).then(extractDataAndPagination);
export const getAllTasks = (params = {}) =>
  client()
    .get('tasks/all', { params })
    .then(extractDataAndPagination<Task>);
export const getClaimProgresses = (params = {}) =>
  client().get('claim_progresses', { params }).then(extractDataAndPagination);
export const getAllPayments = (params = {}) =>
  client().get('payments/all', { params }).then(extractDataAndPagination);

// FrontOffice
export const getFrontOfficeTask = (id: UrlId) =>
  client().get(`/frontoffice/tasks/${id}`).then(extractData);
export const completeFrontOfficeTask = (id: UrlId, values: Task) =>
  client().put(`/frontoffice/tasks/${id}/complete`, { data: values }).then(extractData);
export const completeFrontOfficeTaskBulk = (values: { file: File }) =>
  client().post('frontoffice/tasks/complete', { data: values }).then(extractData);
export const withdrawFrontOfficeTask = (id: ID) =>
  client().patch(`/frontoffice/tasks/${id}/withdraw`).then(extractData);

// Email templates
export const getEmailTemplates: () => Promise<EmailTemplate[]> = () =>
  client().get('/email_templates').then(extractData);
export const getEmailTemplate: (id: UrlId) => Promise<EmailTemplate> = (id) =>
  client().get(`/email_templates/${id}`).then(extractData);
export const deleteEmailTemplate = (id: UrlId) =>
  client().delete(`/email_templates/${id}`).then(extractData);
export const updateEmailTemplate = (id: UrlId, values: Partial<EmailTemplate>) =>
  client()
    .put(`/email_templates/${id}`, { data: { email_template: values } })
    .then(extractData);
export const createEmailTemplate = (values: Partial<EmailTemplate>) =>
  client()
    .post('/email_templates', { data: { email_template: values } })
    .then(extractData);

export default {};
