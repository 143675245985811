import { Col, Row } from 'antd';

import { t } from '@gowgates/utils';
import { ConditionalDescription } from '@gowgates/dynamic-fields';

import useIntegrations from '../../../hooks/useIntegrations';
import useFileConfig from '../../../hooks/useFileConfig';
import useClaim from '../../../hooks/useClaim';

const ClientDescriptions = () => {
  const { clientStructure } = useFileConfig();
  const { hasInsightIntegrations } = useIntegrations();
  const { claim } = useClaim();

  return (
    <dl className="cg-descriptions">
      <Row gutter={24}>
        {hasInsightIntegrations && (
          <>
            <Col span={24}>
              <dt>{t('activerecord.attributes.client.insightSalesTeamName')}</dt>
              <dd>{claim.client.insightSalesTeamName || '-'}</dd>
            </Col>

            <Col span={24}>
              <dt>{t('activerecord.attributes.client.insightServiceTeamName')}</dt>
              <dd>{claim.client.insightServiceTeamName || '-'}</dd>
            </Col>
          </>
        )}

        {clientStructure.map((field) => (
          <ConditionalDescription model={claim.client} field={field} key={field.name} />
        ))}
      </Row>
    </dl>
  );
};

export default ClientDescriptions;
