import { ChangeEventHandler } from 'react';
import { Form, Input, Select, FormInstance } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { t, UrlId } from '@gowgates/utils';
import { FormItem, CurrencySelect } from '@gowgates/dynamic-fields';
import { client } from '@gowgates/api-client';

const getBankStateBranch = (id: UrlId) => client().get(`/bank_state_branches/${id}`);

type BankDetailsFormProps = {
  appConfigs: {
    claim: {
      bankAccountTypes: { label: string; value: string }[];
    };
  };
  nested?: boolean;
  form?: FormInstance;
};

export const BankDetailsForm = ({ appConfigs, nested = false }: BankDetailsFormProps) => {
  const namespace = nested ? ['claim'] : [];
  const form = Form.useFormInstance();
  const queryClient = useQueryClient();

  const onBlurBsb: ChangeEventHandler<HTMLInputElement> = async (event) => {
    const value = event.target.value;
    if (value && /^[0-9]{6}$/.test(value)) {
      try {
        const queryKey = ['bankName', value];
        const cachedData = queryClient.getQueryData<AxiosResponse<{ bankName: string }>>(queryKey);

        if (cachedData) {
          if (form) {
            form.setFieldValue([...namespace, 'bankName'], cachedData.data.bankName);
          }
        }

        const result = await queryClient.fetchQuery({
          queryKey,
          queryFn: () => getBankStateBranch(value),
          staleTime: 60 * 60 * 1000 //1 hour
        });

        if (result.status === 200 && result.data) {
          if (form) {
            form.setFieldValue([...namespace, 'bankName'], result.data.bankName);
          }
        } else {
          form.setFields([
            {
              name: [...namespace, 'bsb'],
              errors: [t('frontoffice.bankForm.bsbInvalidValue')]
            },
            {
              name: [...namespace, 'bankName'],
              value: ''
            }
          ]);
        }
      } catch (error) {
        form.setFields([
          { name: [...namespace, 'bsb'], errors: [t('frontoffice.bankForm.bsbInvalidValue')] },
          { name: [...namespace, 'bankName'], value: '' }
        ]);
      }
    } else {
      form.setFields([
        { name: [...namespace, 'bsb'], errors: [t('frontoffice.bankForm.bsbInvalidFormat')] },
        { name: [...namespace, 'bankName'], value: '' }
      ]);
    }
  };

  return (
    <>
      <FormItem
        name={[...namespace, 'bankAccountType']}
        model="claim"
        required
        extra={t('frontoffice.bankForm.typeDescription')}
      >
        <Select options={appConfigs.claim.bankAccountTypes} />
      </FormItem>

      <Form.Item
        noStyle
        shouldUpdate={(prev, current) =>
          nested
            ? prev.clam?.bankAccountType !== current.claim?.bankAccountType
            : prev.bankAccountType !== current.bankAccountType
        }
      >
        {({ getFieldValue }) => {
          const bankAccountType = getFieldValue([...namespace, 'bankAccountType']);

          return (
            <>
              {bankAccountType === 'australia' && (
                <>
                  <FormItem
                    name={[...namespace, 'bsb']}
                    model="claim"
                    required
                    extra={t('frontoffice.bankForm.bsbDescription')}
                  >
                    <Input onBlur={onBlurBsb} maxLength={6} />
                  </FormItem>
                  <>
                    <FormItem name={[...namespace, 'bankName']} model="claim" required>
                      <Input />
                    </FormItem>
                    <FormItem name={[...namespace, 'bankAccountHolderName']} model="claim" required>
                      <Input />
                    </FormItem>
                  </>
                  <FormItem name={[...namespace, 'accountNumber']} model="claim" required>
                    <Input />
                  </FormItem>
                </>
              )}

              {bankAccountType === 'overseas' && (
                <>
                  <>
                    <FormItem name={[...namespace, 'bankName']} model="claim" required>
                      <Input />
                    </FormItem>
                    <FormItem name={[...namespace, 'bankAccountHolderName']} model="claim" required>
                      <Input />
                    </FormItem>
                  </>
                  <FormItem name={[...namespace, 'swift']} model="claim" required>
                    <Input />
                  </FormItem>
                  <FormItem name={[...namespace, 'iban']} model="claim" required>
                    <Input />
                  </FormItem>
                  <FormItem name={[...namespace, 'currency']} model="claim" required>
                    <CurrencySelect />
                  </FormItem>
                </>
              )}
            </>
          );
        }}
      </Form.Item>
    </>
  );
};
