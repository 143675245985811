import { DynamicDescriptions } from '@gowgates/dynamic-fields';
import useFileConfig from '../../../hooks/useFileConfig';
import useClaim from '../../../hooks/useClaim';

const PolicyDescriptions = () => {
  const { coverStructure } = useFileConfig();
  const { claim } = useClaim();

  return <DynamicDescriptions model={claim.cover} fields={coverStructure} />;
};

export default PolicyDescriptions;
