import { Col, Row } from 'antd';
import Box from '../../../../components/Box';
import useClaim from '../../../../hooks/useClaim';
import ClaimDetailsForm from '../../forms/ClaimDetails';
import CreateClaimForm from '../CreateClaimForm';

const CreateClaimDetails = () => {
  const { claim } = useClaim();

  return (
    <CreateClaimForm claim={claim}>
      <Box>
        <Row>
          <Col span={14} offset={5}>
            <ClaimDetailsForm claimStructure={claim.structure} />
          </Col>
        </Row>
      </Box>
    </CreateClaimForm>
  );
};

export default CreateClaimDetails;
