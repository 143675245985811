import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, ConfigProvider, Row, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';
import { getItem } from '../../../../../api/endpoints';
import ItemsTable from '../../../components/Items/Table';
import EditItemModal from '../../../components/Items/EditItemModal';
import SectionForm from '../../../forms/Section';
import DeleteSectionBtn from '../../../components/Sections/DeleteSectionBtn';
import useClaim from '../../../../../hooks/useClaim';
import LoadingHover from '../../../../../components/LoadingHover';

const SectionDetails = ({ section, sectionStructure }) => {
  const { claim } = useClaim();

  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoadingItem, setLoadingItem] = useState(false);
  const [currentItem, setCurrentItem] = useState({ data: {} });

  const editItem = (item) => {
    setLoadingItem(true);
    getItem(claim.id, item.id).then((data) => {
      setCurrentItem(data);
      setModalOpen(true);
      setTimeout(() => {
        setLoadingItem(false);
      }, 200);
    });
  };

  const addItem = () => {
    setCurrentItem({ sectionId: section.id });
    setModalOpen(true);
  };

  return (
    <Space direction="vertical" size="large" className="w-100">
      <Row>
        <Col span={14} offset={5}>
          <SectionForm sectionStructure={sectionStructure} namespaced />
        </Col>
      </Row>

      <ConfigProvider
        renderEmpty={() => <em>{t('item.emptyTable', { section: sectionStructure.name })}</em>}
      >
        <ItemsTable
          claim={claim}
          sectionStructure={sectionStructure}
          section={section}
          editItem={editItem}
        />
      </ConfigProvider>

      <EditItemModal
        isOpen={isModalOpen}
        setOpen={setModalOpen}
        item={currentItem}
        structure={sectionStructure}
      />

      <div className="d-flex justify-content-between">
        <span>
          <DeleteSectionBtn section={section} />
        </span>

        <Button type="primary" ghost onClick={addItem} icon={<PlusOutlined />}>
          {t('item.add')}
        </Button>
      </div>

      <LoadingHover open={isLoadingItem} />
    </Space>
  );
};

SectionDetails.propTypes = {
  section: PropTypes.object.isRequired,
  sectionStructure: PropTypes.object.isRequired
};

export default SectionDetails;
