import PropTypes from 'prop-types';
import { t } from '@gowgates/utils';
import { DynamicDescriptions } from '@gowgates/dynamic-fields';

const ItemDescriptions = ({ item, structure }) => (
  <>
    <dl className="cg-descriptions">
      <dt>{t('activerecord.attributes.item.category')}</dt>
      <dd>{t(`item.categories.${item.category}`)}</dd>
    </dl>

    <DynamicDescriptions model={item} fields={structure.items} />
  </>
);

ItemDescriptions.propTypes = {
  item: PropTypes.object.isRequired,
  structure: PropTypes.object.isRequired
};

export default ItemDescriptions;
