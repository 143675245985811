import { PropsWithChildren, useRef } from 'react';
import dayjs from 'dayjs';
import { Col, DatePicker, GetProps, Input, InputRef, Row, Tooltip } from 'antd';
import { useLocation } from 'react-router';
import { snakeCase } from 'change-case';
import qs from 'query-string';
import pluralize from 'pluralize';
import { t, formatPrice, AnyObject } from '@gowgates/utils';
import { CustomTableFilter, HighlightText } from '@gowgates/core';
import { DateFormatter } from '@gowgates/dynamic-fields';

import { TableSorter } from '../utils/tables';

type Props = {
  useEllipsis?: boolean;
  columnWidth?: number;
};

type Configs = {
  sorter?: TableSorter;
  filters?: AnyObject;
  cellContainer?: React.FC<PropsWithChildren<{ record: AnyObject }>>;
  type?: string;
  filterOptions?: AnyObject;
  textFilter?: boolean;
  title?: string;
  render?: (value: any, record: AnyObject, index: number) => React.ReactNode | undefined;
};

type FilterDropdownType = {
  setSelectedKeys: (keys: (string | null)[] | null) => void;
  selectedKeys: (string | null)[] | null;
  confirm: () => void;
  clearFilters: () => void;
};

type TooltipPropsType = GetProps<typeof Tooltip>;

const TooltipComponentWrapper: React.FC<TooltipPropsType> = ({ title, children, ...props }) => (
  <Tooltip placement="topLeft" title={title} {...props}>
    {children}
  </Tooltip>
);

const TooltipByPass: React.FC<
  TooltipPropsType
> = /* Wrapper to avoid props warning because Fragment does not have title */ ({
  children
}: TooltipPropsType) => <>{children}</>;

const useLegacyTableSearch = ({ useEllipsis = false, columnWidth }: Props = {}) => {
  const search = qs.parse(useLocation().search);
  const searchInput = useRef<InputRef>(null);

  const TooltipComponent = useEllipsis ? TooltipComponentWrapper : TooltipByPass;

  const sortProps = (column: string, sorter: TableSorter) => ({
    sorter: true,
    sortOrder: sorter.field === column && sorter.order
  });

  const handleReset = (clearFilters: () => void, confirm: () => void) => {
    clearFilters();
    confirm();
  };

  const filteredValue = (dataIndex: string, filters: AnyObject) => filters[dataIndex] || null;

  const textSearchProps = (dataIndex: string, filters: AnyObject) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }: FilterDropdownType) => (
      <CustomTableFilter
        clearFilters={clearFilters}
        confirm={confirm}
        handleReset={handleReset}
        disabled={selectedKeys ? !selectedKeys[0] : false}
      >
        <Input
          ref={searchInput}
          value={selectedKeys ? selectedKeys[0] || undefined : undefined}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          placeholder={t('globals.textToSearch')}
        />
      </CustomTableFilter>
    ),
    filteredValue: filteredValue(dataIndex, filters),
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (_text: any, record: AnyObject) => (
      <HighlightText
        searchWords={[search[snakeCase(dataIndex)]] as string[]}
        text={record[dataIndex]}
      />
    )
  });

  const dateSearchProps = (dataIndex: string, filters: AnyObject) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }: FilterDropdownType) => {
      const values =
        selectedKeys &&
        (selectedKeys.length !== 0 ? [dayjs(selectedKeys[0]), dayjs(selectedKeys[1])] : undefined);

      return (
        <CustomTableFilter
          clearFilters={clearFilters}
          confirm={confirm}
          handleReset={handleReset}
          disabled={selectedKeys ? !selectedKeys[0] && !selectedKeys[1] : false}
        >
          <DatePicker.RangePicker
            onChange={(value, strValue) => setSelectedKeys(value ? strValue : null)}
            value={values as any}
          />
        </CustomTableFilter>
      );
    },
    filteredValue: filteredValue(dataIndex, filters)
  });

  const numberSearchProps = (dataIndex: string, filters: AnyObject) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }: FilterDropdownType) => {
      const setMin: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        if (event.target.value) {
          setSelectedKeys([`min:${event.target.value}`, selectedKeys && selectedKeys[1]]);
        } else {
          setSelectedKeys([null, selectedKeys && selectedKeys[1]]);
        }
      };

      const setMax: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        if (event.target.value) {
          setSelectedKeys([selectedKeys && selectedKeys[0], `max:${event.target.value}`]);
        } else {
          setSelectedKeys([selectedKeys && selectedKeys[0], null]);
        }
      };

      return (
        <CustomTableFilter
          clearFilters={clearFilters}
          confirm={confirm}
          handleReset={handleReset}
          disabled={selectedKeys ? !selectedKeys[0] && !selectedKeys[1] : false}
        >
          <Row gutter={12}>
            <Col span={12}>
              <Input
                ref={searchInput}
                value={
                  (selectedKeys && selectedKeys[0] && selectedKeys[0].split(':')[1]) || undefined
                }
                onChange={setMin}
                onPressEnter={() => confirm()}
                placeholder={t('globals.from')}
              />
            </Col>
            <Col span={12}>
              <Input
                ref={searchInput}
                value={
                  (selectedKeys && selectedKeys[1] && selectedKeys[1].split(':')[1]) || undefined
                }
                onChange={setMax}
                onPressEnter={() => confirm()}
                placeholder={t('globals.until')}
              />
            </Col>
          </Row>
        </CustomTableFilter>
      );
    },
    filteredValue: filteredValue(dataIndex, filters)
  });

  const filterProps = (column: string, values: AnyObject, filters: AnyObject) => ({
    filters: values,
    filteredValue: filters[column] || null
  });

  const formatText = (
    type: string | undefined,
    dataIndex: string,
    modelName: string,
    text: string | number | numeral.Numeral,
    record: AnyObject
  ) => {
    if (type === 'date') {
      return <DateFormatter value={record[dataIndex]} TooltipComponent={TooltipComponent} />;
    }

    if (type === 'datetime') {
      return (
        <DateFormatter
          value={record[dataIndex]}
          TooltipComponent={TooltipComponent}
          format="minute"
        />
      );
    }

    if (type === 'money') {
      return (
        <TooltipComponent
          title={formatPrice({ value: text, currency: record[`${dataIndex}Currency`] })}
        >
          {formatPrice({ value: text, currency: record[`${dataIndex}Currency`] })}
        </TooltipComponent>
      );
    }

    if (type === 'enum') {
      return text ? (
        <TooltipComponent title={t(`${modelName}.${pluralize(dataIndex)}.${text}`)}>
          {t(`${modelName}.${pluralize(dataIndex)}.${text}`)}
        </TooltipComponent>
      ) : (
        ''
      );
    }

    return <TooltipComponent title={String(text)}>{String(text)}</TooltipComponent>;
  };

  const columnProps = (dataIndex: string, modelName: string, config: Configs = {}) => {
    const title = config.title || t(`activerecord.attributes.${modelName}.${dataIndex}`);
    let props: any = {
      title: <TooltipComponent title={title}>{title}</TooltipComponent>,
      dataIndex,
      showSorterTooltip: !useEllipsis,
      width: typeof columnWidth === 'undefined' ? undefined : columnWidth,
      ellipsis: useEllipsis
        ? {
            showTitle: true
          }
        : undefined
    };

    if (config.sorter) {
      props = { ...props, ...sortProps(dataIndex, config.sorter) };
    }

    if (config.filters && config.filterOptions) {
      props = { ...props, ...filterProps(dataIndex, config.filterOptions, config.filters) };
    }

    if (config.filters && config.textFilter) {
      props = { ...props, ...textSearchProps(dataIndex, config.filters) };
    }

    if (config.filters && config.type === 'date') {
      props = { ...props, ...dateSearchProps(dataIndex, config.filters) };
    }

    if (config.filters && config.type === 'money') {
      props = { ...props, ...numberSearchProps(dataIndex, config.filters) };
    }

    if (config.cellContainer) {
      const CellContainerComponent = config.cellContainer;
      props.render = (text: any, record: AnyObject) => (
        <CellContainerComponent record={record}>
          {formatText(config.type, dataIndex, modelName, text, record)}
        </CellContainerComponent>
      );
    } else {
      props.render =
        config.render ||
        ((text: any, record: AnyObject) =>
          formatText(config.type, dataIndex, modelName, text, record));
    }

    return props;
  };

  return {
    sortProps,
    textSearchProps,
    filterProps,
    columnProps,
    TooltipComponent
  };
};

export default useLegacyTableSearch;
