import { Col, Row } from 'antd';
import Box from '../../../../components/Box';
import ClaimantDetailsForm from '../../forms/ClaimantDetails';
import ClientDetailsForm from '../../forms/ClientDetails';
import FileDetailsForm from '../../forms/FileDetails';
import PolicyDetailsForm from '../../forms/PolicyDetails';
import CreateClaimForm from '../CreateClaimForm';
import useClaim from '../../../../hooks/useClaim';
import useAppConfigs from '../../../../hooks/useAppConfigs';

const CreateUnderwriting = () => {
  const { claim } = useClaim();
  const { appConfigs } = useAppConfigs();

  if (!appConfigs) return null;
  const fileConfig = appConfigs.fileConfig;

  return (
    <CreateClaimForm claim={claim} nestedModels={['client', 'claimant', 'cover']}>
      {(setUpdating) => (
        <>
          <Box title={fileConfig.fileDetailsLabel}>
            <Row>
              <Col span={14} offset={5}>
                <FileDetailsForm setUpdating={setUpdating} />
              </Col>
            </Row>
          </Box>
          {fileConfig.policyDetailsActive && (
            <Box title={fileConfig.policyDetailsLabel}>
              <Row>
                <Col span={14} offset={5}>
                  <PolicyDetailsForm nested />
                </Col>
              </Row>
            </Box>
          )}
          {fileConfig.clientDetailsActive && (
            <Box title={fileConfig.clientDetailsLabel}>
              <Row>
                <Col span={14} offset={5}>
                  <ClientDetailsForm nested />
                </Col>
              </Row>
            </Box>
          )}
          <Box title={fileConfig.claimantDetailsLabel}>
            <Row>
              <Col span={14} offset={5}>
                <ClaimantDetailsForm nested />
              </Col>
            </Row>
          </Box>
        </>
      )}
    </CreateClaimForm>
  );
};

export default CreateUnderwriting;
