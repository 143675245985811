import { useEffect, useState } from 'react';

export const useCurrencies = () => {
  const [currencies, setCurrencies] = useState<
    {
      key: string;
      name: string;
      symbol: string;
    }[]
  >();

  useEffect(() => {
    import('../resources/currencies.json').then((currenciesModule) => {
      setCurrencies(currenciesModule.default);
    });
  }, []);

  return { currencies };
};
