import React from 'react';
import PropTypes from 'prop-types';
import { CreateStepsContextProvider } from '../../../contexts/CreateStepsContext';
import CreateClaim from '.';

const CreateClaimContainer = ({ claim }) => (
  <CreateStepsContextProvider claim={claim}>
    <CreateClaim claim={claim} />
  </CreateStepsContextProvider>
);

CreateClaimContainer.propTypes = {
  claim: PropTypes.object.isRequired
};

export default CreateClaimContainer;
