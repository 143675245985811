import { DateFormatNames, formatDate } from '@gowgates/utils';
import { GetProps, Tooltip } from 'antd';

type TooltipPropsType = GetProps<typeof Tooltip>;

type DateFormatterProps = {
  value: string;
  format?: DateFormatNames;
  TooltipComponent?: React.FC<TooltipPropsType>;
};

export const DateFormatter = ({
  value = '',
  format = 'day',
  TooltipComponent
}: DateFormatterProps) => {
  const output = <time dateTime={value}>{formatDate(value, format)}</time>;

  if (!TooltipComponent) {
    return output;
  }

  return <TooltipComponent title={formatDate(value, format)}>{output}</TooltipComponent>;
};
