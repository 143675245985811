import PropTypes from 'prop-types';
import { t, formatPrice } from '@gowgates/utils';
import { DateFormatter } from '@gowgates/dynamic-fields';

const ItemPaymentFields = ({ item }) => (
  <dl className="cg-descriptions">
    <dt>{t('activerecord.attributes.item.payablePercentage')}</dt>
    <dd>{`${item.payablePercentage} %`}</dd>

    <dt>{t('activerecord.attributes.item.excess')}</dt>
    <dd>{formatPrice({ value: item.excess })}</dd>

    <dt>{t('activerecord.attributes.item.paymentAmount')}</dt>
    <dd>{formatPrice({ value: item.paymentAmount })}</dd>

    <dt>{t('activerecord.attributes.item.paidAt')}</dt>
    <dd>
      <DateFormatter value={item.paidAt} />
    </dd>
  </dl>
);

ItemPaymentFields.propTypes = {
  item: PropTypes.object.isRequired
};

export default ItemPaymentFields;
