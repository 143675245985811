import { ReactElement, ReactNode, useState } from 'react';
import { Tooltip, Space, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { MutationFunction, QueryKey } from '@tanstack/react-query';

import { ExtendedDangerPopconfirm } from '@gowgates/dynamic-fields';
import { AnyObject, t } from '@gowgates/utils';

import InnerBox from '../InnerBox';
import FormElement from './FormElement';

type EditableDescriptionsProps = {
  title: ReactNode;
  showPermission?: boolean;
  editPermission?: boolean;
  descriptions: ReactNode;
  form: ReactElement;
  model: AnyObject;
  updateModel: (model: AnyObject) => Promise<AnyObject>;
  destroyModel?: MutationFunction;
  successMessage?: string;
  queryDataKey?: QueryKey | QueryKey[];
  onSuccess?: (data: AnyObject) => void;
  noLine?: boolean;
};

const EditableDescriptions = ({
  title,
  showPermission = false,
  editPermission = false,
  descriptions,
  form,
  model,
  updateModel,
  destroyModel,
  successMessage = 'globals.saved',
  queryDataKey,
  onSuccess = () => {},
  noLine = false
}: EditableDescriptionsProps) => {
  const [isEditing, setEditing] = useState(false);

  if (!showPermission) {
    return null;
  }

  const startEdit = () => {
    setEditing(true);
  };

  const cancelEdit = () => {
    setEditing(false);
  };

  return (
    <InnerBox
      noLine={noLine}
      title={
        <Space size="middle">
          <span>{title}</span>
          {!isEditing && editPermission && (
            <Space>
              <Tooltip title={t('globals.edit')}>
                <Typography.Link onClick={startEdit}>
                  <EditOutlined />
                </Typography.Link>
              </Tooltip>

              {destroyModel && (
                <ExtendedDangerPopconfirm
                  deleteFn={destroyModel}
                  setQueryData={queryDataKey}
                  permission // no need to check permission because there is only one for journeys
                />
              )}
            </Space>
          )}
        </Space>
      }
    >
      {isEditing ? (
        <FormElement
          cancelEdit={cancelEdit}
          model={model}
          updateModel={updateModel}
          successMessage={successMessage}
          queryDataKey={queryDataKey}
          onSuccess={onSuccess}
        >
          {form}
        </FormElement>
      ) : (
        descriptions
      )}
    </InnerBox>
  );
};

export default EditableDescriptions;
